.headline {
  width: calc(100% - 4rem);
  @apply break-all;
}

.icon {
  height: auto;
  top: -0.5em;
  @apply relative lg:absolute lg:-left-80 w-40 lg:w-48;
}
