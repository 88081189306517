.noteGrid {
  @apply grid gap-32 justify-between;
}

@media screen(sm) {
  .noteGrid {
    grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
  }
}

.note {
  @apply h-384 text-12 flex flex-col p-32 gap-48 transition-transform;
}

.link {
  position: relative;
  cursor: pointer;

  @apply bg-current text-27 font-light tracking-0;

  /* Animation of underlining */

  background:
    linear-gradient(to right, currentcolor, currentcolor),
    linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, currentcolor, currentcolor);
  background-size:
    100% 1px,
    0% 1px,
    0% 1px;
  background-position:
    100% 100%,
    0 100%,
    0 100%;
  background-repeat: no-repeat;
}

.card:hover .link {
  animation-name: underlining;
  animation-duration: 700ms;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@keyframes underlining {
  50% {
    background-size:
      0% 1px,
      100% 1px,
      0% 1px;
  }

  100% {
    background-size:
      0% 1px,
      0% 1px,
      100% 1px;
  }
}

.card {
  @apply justify-between bg-background;
}

@media (prefers-reduced-motion: no-preference) {
  .card:hover {
    @apply scale-105;
  }
}
