@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .fadeDelaySequence > * {
    animation-delay: 300ms;
    animation-name: fade-out;
    animation-duration: 0.1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .fadeDelaySequence > *:nth-child(1) {
    animation-delay: 100ms;
  }
  .fadeDelaySequence > *:nth-child(2) {
    animation-delay: 150ms;
  }
  .fadeDelaySequence > *:nth-child(3) {
    animation-delay: 200ms;
  }
  .fadeDelaySequence > *:nth-child(4) {
    animation-delay: 250ms;
  }

  .fadeDelaySequence.active > * {
    animation-name: fade-in;
    animation-fill-mode: backwards;
  }

  .mobileMenuFooter {
    animation: fade-out 0.2s linear 0.4s;
    animation-fill-mode: forwards;
  }

  .mobileMenuFooterActive {
    animation: fade-in 0.2s linear 0.6s;
    animation-fill-mode: backwards;
  }
}

.wide {
  display: none;
}

.tall {
  display: block;
}

@media (aspect-ratio >= 5/4) {
  .wide {
    display: block;
  }

  .tall {
    display: none;
  }
}

.extraHigh {
  height: calc(100vh + 2rem);
}

.underline {
  @apply w-full relative lg:text-highlight overflow-hidden lg:transition-[visibility] invisible;
}

:global(.group):hover .underline,
.underlineActive {
  @apply visible;
}

@media (screen(lg)) {
  .underline::before {
    @apply absolute bg-background w-full h-full left-0 top-0 transition-[width];

    content: '';
  }

  .underline::after {
    @apply absolute  bg-background w-full h-full right-0 top-0 transition-[width] invisible;

    content: '';
  }

  :global(.group):hover .underline::before,
  .underlineActive::before {
    @apply w-0 transition-none;
  }

  :global(.group):hover .underline::after,
  .underlineActive::after {
    @apply w-0 delay-[0] duration-150 visible;
  }
}
