.metadataGrid {
  @apply grid gap-80 md:gap-x-128;

  grid-template-areas: 'headline' 'content' 'contact';
}

@media screen(sm) {
  .metadataGrid {
    grid-template-columns: 16rem 2fr;
    grid-template-areas: 'contact headline' 'contact content';
  }
}
