.main {
  @apply relative transition-all inline-block duration-500;

  padding-left: 3.1em;
}

.fmx {
  @apply inline-flex flex-row absolute left-0 transition-all items-center h-full duration-300;

  gap: 0.8em;
}

@media screen and (prefers-reduced-motion: no-preference) {
  :global(.group):hover .main {
    @apply pl-0;
  }

  :global(.group):hover .fmx {
    @apply opacity-0 translate-x-16;
  }
}
