.heroGrid {
  @apply grid gap-40 md:gap-x-64 lg:gap-y-96 xl:gap-x-128 items-start;

  align-content: start;
  grid-template-areas: 'heading' 'photo' 'meta';
}

@media screen(sm) {
  .heroGrid {
    grid-template: 'photo heading' max-content 'photo meta' 1fr / 16rem 1fr;
  }
}

@media screen(md) {
  .heroGrid {
    grid-template-columns: 20rem 1fr;
  }
}

@media screen(xl) {
  .heroGrid {
    grid-template-columns: 32rem 1fr;
  }
}
