.listItem p {
  @apply inline-block;
}

.listItem {
  @apply pb-16 pl-40 relative;
}

.listItem::before {
  @apply text-highlight font-bold inline-block absolute;

  content: '\2022';
  width: 1em;
  margin-right: 1em;
  top: 0;
  left: 0;
}
