.main {
  @apply relative h-48 grid items-center text-12 font-bold tracking-50 py-16 uppercase whitespace-nowrap text-center;

  /* set background color without setting --background-color */
  background-color: theme(colors.black);
}

.main:not(.outlined) {
  @apply text-background;
}

.label {
  @apply w-full overflow-visible px-16;
}

.arrow {
  @apply absolute right-32 opacity-0 transition-all hidden duration-500;
}

.main:hover .arrow {
  @apply right-24 opacity-100;
}

.main.withArrow {
  @apply min-w-192 px-48;
}

.main.withArrow.slim {
  @apply px-16;
}

.main.withArrow .spacer {
  @apply inline;
}

.main.withArrow .label {
  @apply transition-all overflow-visible w-full absolute left-0 px-24 duration-500;
}

.main.withArrow:hover .label {
  @apply w-0;
}

.main.withArrow .arrow {
  @apply inline;
}

.spacer {
  @apply hidden invisible px-4;
}

@media screen(md) {
  .spacer {
    @apply hidden invisible px-16;
  }

  .main.withArrowAtMd {
    @apply min-w-192 px-48;
  }

  .main.withArrowAtMd.slim {
    @apply px-16;
  }

  .main.withArrowAtMd .spacer {
    @apply inline;
  }

  .main.withArrowAtMd .label {
    @apply transition-all overflow-visible w-full absolute left-0 px-16 duration-500;
  }

  .main.withArrowAtMd:hover .label {
    @apply w-0;
  }

  .main.withArrowAtMd .arrow {
    @apply inline;
  }
}

.main.outlined {
  @apply border;

  background: none;
}

.main.outlined:disabled {
  @apply border-dashed;
}
