.teaserIllustration {
  @apply flex flex-col justify-center items-center aspect-video relative motion-safe:hover:scale-105 transition-transform;

  padding: 20% 0;
}

.gridContainer {
  --grid-column-count: 4; /* This gets overridden by an inline style. */
  --grid-item-min-width: 350px;
  --grid-item-max-width: calc(
    (100% - (4 - 1) * 96px) / var(--grid-column-count)
  );

  @apply grid gap-x-24 gap-y-64 lg:gap-64 xl:gap-96;
}

@media screen(sm) {
  .gridContainer {
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr)
    );
  }
}
