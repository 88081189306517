.footerGrid {
  @apply grid py-48 md:pt-80 gap-32 md:gap-0;

  grid-template-areas: 'logo' 'addressOne' 'addressTwo' 'social' 'contact' 'map' 'imprint' 'copyright';
}

@media (min-width: theme('screens.md')) {
  .footerGrid {
    grid-template: 'logo addressOne social' 1fr 'map addressTwo addressTwo' 1fr 'map contact contact' 1fr 'imprint imprint imprint' auto / 2fr 1fr 1fr;
  }
}

.logo {
  @apply h-40 mb-24;

  grid-area: logo;
}

.addressOne {
  @apply grid gap-16 place-items-start place-content-start;

  grid-area: addressOne;
}

.addressTwo {
  @apply grid gap-16 place-items-start place-content-start;

  grid-area: addressTwo;
}

.social {
  @apply grid gap-16 md:justify-end;

  grid-area: social;
}

.contact {
  @apply grid gap-16 relative place-items-start place-content-start;

  grid-area: contact;
}

.map {
  @apply flex relative justify-center;

  grid-area: map;
}

.imprint {
  @apply grid justify-items-center gap-8 md:grid-cols-2 md:justify-items-start;

  grid-area: imprint;
}
