.scrollBlock {
  overflow-y: hidden;
  height: 100vh;
  position: fixed;
}

@screen lg {
  .scrollBlock {
    overflow-y: unset;
    height: unset;
  }
}
