.content > * {
  @apply mt-32;
}

.content p + p {
  @apply mt-16;
}

.content h2 {
  /* this is supposed to look like a h3 */
  @apply text-30 font-light tracking-0;
}

.content h3 {
  /* this is supposed to look like a h4 */
  @apply text-27 font-light tracking-0;
}
