.link {
  position: relative;
  cursor: pointer;

  @apply bg-current text-27 font-light tracking-0;

  /* Animation of underlining */

  background:
    linear-gradient(to right, currentcolor, currentcolor),
    linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, currentcolor, currentcolor);
  background-size:
    100% 1px,
    0% 1px,
    0% 1px;
  background-position:
    100% 100%,
    0 100%,
    0 100%;
  background-repeat: no-repeat;
}

.projectCard:hover .link {
  animation-name: underlining;
  animation-duration: 700ms;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@keyframes underlining {
  50% {
    background-size:
      0% 1px,
      100% 1px,
      0% 1px;
  }

  100% {
    background-size:
      0% 1px,
      0% 1px,
      100% 1px;
  }
}

.projectCard {
  @apply h-[28rem] bg-background;
}

@media (prefers-reduced-motion: no-preference) {
  .projectCard:hover {
    @apply scale-105;
  }
}
